import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import 'firebase/analytics';
@Injectable()
export class AnalyticsService {
private analytics: any;
    constructor() {
        // use any one of the codes below depends on the version
       // firebase version 8
        this.analytics = firebase.default.analytics();
    }
  logEvents(eventName: String,params: any): void {
          // shared method to log the events
          this.analytics.logEvent(eventName,params);
      }
}