import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgModule }      from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { IProducts } from 'app/model/products';
import { FirebaseService } from 'app/service/firebase.service';
import { map } from 'rxjs/operators';
import { FormBuilder, Validators } from '@angular/forms'
import Swal from 'sweetalert2'
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { AnalyticsService } from 'app/service/analytics.service';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.css']
})
export class ProductDetailsComponent implements OnInit {



  id:string
  email:string
  message:string
  buyLink:string



    constructor(analytics: AnalyticsService,private firebaseService: FirebaseService,private route: ActivatedRoute,private modalService: NgbModal) {
      route.params.subscribe(
       (params) => {
         this.id =  params['id'];
         
         this.getProducts();
         analytics.logEvents('product_page', { id:this.id });
       });
       
      

    }

    product?: IProducts;

    ngOnInit(): void {
     
    }

    getProducts(): void {

    
      this.firebaseService.getProduct(this.id).snapshotChanges().pipe(
        map(changes =>
          changes.map(c =>
            ({ ...c.payload.doc.data() })
          )
        )
      ).subscribe(data => {
        //console.log(this.product)
        this.product = data[0];

      });
    }

    getPrice(item,value){
      if(item=="EUR"){
        return value.toString() + " €"
      }
      if(item=="BRL"){
        return "R$ " + value.toString()
      }
    }


    sendmessage(product) {
      this.firebaseService.genericEmail({email:this.email,name:product,subject:this.message}).subscribe(result=>{
        Swal.fire(
          'Message Sent!',
          'We will reach you very soon, stay tuned 🎉',
          'success'
        )
        this.email = ""
        this.message = ""
      });

    }

    getText(){
      return encodeURIComponent("Hey! I'm interest in this product: "+this.product.title)
    }

    updateLink(){
      //console.log(this.product.link[this.buyLink])
    }
}
