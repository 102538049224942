import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LandingComponent } from './landing/landing.component';
import { ProfileComponent } from './profile/profile.component';
import { SignupComponent } from './signup/signup.component';
import { StoreComponent } from './store/store.component';
import { ProductDetailsComponent } from './product-details/product-details.component';
import {IvyCarouselModule} from 'angular-responsive-carousel';
import { BrowserModule } from '@angular/platform-browser';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        IvyCarouselModule,
        ReactiveFormsModule,
        BrowserModule

    ],
    declarations: [
        LandingComponent,
        SignupComponent,
        ProfileComponent,
        StoreComponent,
        ProductDetailsComponent
    ]
})
export class ExamplesModule { }
