import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2'

@Component({
    selector: 'app-landing',
    templateUrl: './landing.component.html',
    styleUrls: ['./landing.component.scss']
})

export class LandingComponent implements OnInit {
  focus: any;
  focus1: any;

  currency: string;
  links:[{},{},{}];

  constructor(private router: Router,
    ) { 
      this.currency="EUR"

      this.links=[
        {
        "EUR":{
          "value":"1 €",
          "link":"https://buy.stripe.com/cN2eYg435fvL8PmeUZ"
        },
        "BRL":{
          "value":"R$ 5",
          "link":"https://buy.stripe.com/9AQ7vOgPR97ne9G3cr"
        }
      },
      {
        "EUR":{
          "value":"5 €",
          "link":"https://buy.stripe.com/cN2dUc4355Vbd5C4gk"
        },
        "BRL":{
          "value":"R$ 30",
          "link":"https://buy.stripe.com/00gbM46bdabr2qYdR4"
        }
      },
      {
        "EUR":{
          "value":"20 €",
          "link":"https://buy.stripe.com/dR64jCartbfv0iQ4gj"
        },
        "BRL":{
          "value":"R$ 100",
          "link":"https://buy.stripe.com/00g2buart1EV8Pm7sF"
        }
      }
    ]




   
    }

  ngOnInit(

  ) {

    // Swal.fire({
    //   title: 'Help us buy a drone \n\nCheck out how to contribute!',
    //   width: 600,
    //   padding: '3em',
    //   background: '#fff url(/images/trees.png)',
    //   showCancelButton: false,

    //   backdrop: `
    //     rgba(0,0,123,0.4)
    //     url("/assets/img/drone.gif")
    //     left bottom
    //     no-repeat
    //   `
    // }).then((result) => {
    //   if (result.isConfirmed) {

      


    //   }
    // })
    
  }

  changeCurrency(event){
    this.currency=event
  }

}
