import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { IProducts } from 'app/model/products';
import { Observable } from 'rxjs';
import { AngularFireFunctions } from '@angular/fire/functions';


@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  products: AngularFirestoreCollection<IProducts>;
  product: AngularFirestoreCollection<IProducts>;


  constructor(private firestore: AngularFirestore,private fns: AngularFireFunctions) { 

  }

  genericEmail(data): Observable<any> {

    const getUserData = this.fns.httpsCallable('genericEmail')

    return getUserData(data)

}


  getProducts(){
    this.products = this.firestore.collection("products",ref => ref.where("available", "!=", false));
    return this.products
  }

  getProduct(id) { 
    this.product = this.firestore.collection("products", ref => ref.where("id", "==", id));
    return this.product
  }
}
