// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyChaSULtFOPJ-iobbz1g-G_68hZ6u8w5lk",
    authDomain: "marmita-landing.firebaseapp.com",
    projectId: "marmita-landing",
    storageBucket: "marmita-landing.appspot.com",
    messagingSenderId: "508718665430",
    appId: "1:508718665430:web:554974006c7bcf18d8c377",
    measurementId: "G-M69T862RCP"
  },
};
