import { Component, OnInit } from '@angular/core';
import { IProducts } from 'app/model/products';
import { map } from 'rxjs/operators';
import { FirebaseService } from 'app/service/firebase.service';
import { Data } from '@angular/router';

@Component({
  selector: 'app-store',
  templateUrl: './store.component.html',
  styleUrls: ['./store.component.css']
})
export class StoreComponent implements OnInit {

  constructor(private firebaseService: FirebaseService) { }
  products?:any
  tProd?:IProducts[]
  public total:number
  donated:number

  ngOnInit(): void {
    this.products = {}
    this.tProd = []
    this.total = 0
    this.donated = 0
    this.getProducts()
  }

  getProducts(): void {

  
    this.firebaseService.getProducts().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ ...c.payload.doc.data() })
        )
      )
    ).subscribe(data => {
      this.tProd = data;
      this.products = {}
      this.total = 1299
      this.donated = 63
      for (let p =0; p < this.tProd.length;p++){
          let type = this.tProd[p].type as string

          this.products[type] = this.products[type] || [];
          this.products[type].push(this.tProd[p])

          // this.total = this.total+(this.tProd[p].price)/2
          // if(this.tProd[p].qty==0){
          //   this.donated = (this.donated)+(this.tProd[p].price)/2
          // }
      }
    });
  }

  getPrice(item,value){
    if(item=="EUR"){
      return value.toString() + " €"
    }
    if(item=="BRL"){
      return "R$ " + value.toString()
    }
  }

  getPercentage(){
    return (this.donated/this.total)*100
  }
}
